import { companyListES } from 'src/app/modules/company-list/company-list';
import { statisticsES } from 'src/app/modules/company-list/general-statistics/statitics';
import { docFormationES } from 'src/app/modules/company-view/doc-formation/doc-formation';
import { expirationStudioES } from 'src/app/modules/expirations/expiration-list/expiration';
import { IndividualsES } from 'src/app/modules/individuals/individuals';
import { ShareInfoTableES } from 'src/app/modules/information-shared/information-shared.model';
import { PowersES } from 'src/app/modules/powers-upload/powers-upload';
import {
  ActionTypeChileES,
  ActionTypeES,
  CountriesEs,
  DocumentTypeEs,
  ExpirationRangeEs,
  ExpirationTypeEs,
  POAStausEs,
  PowerTypeEs,
  RolePersonEs,
  ShareInfoEs,
  TratamientoES,
  UserTypeEs,
} from 'src/app/modules/shared/combos-lang';
import { userMenuES } from 'src/app/pages/layout/navbar/models/profile-options';

export const Spanish = {
  lang: 'ES',
  data: {
    INDV: IndividualsES,
    USER_MENU: userMenuES,
    COUNTRIES: CountriesEs,
    EXPIRATIONRANGE: ExpirationRangeEs,
    EXPIRATIONTYPE: ExpirationTypeEs,
    POASTATUS: POAStausEs,
    SHAREINFO: ShareInfoEs,
    ROLEPERSON: RolePersonEs,
    DOCUMENTTYPE: DocumentTypeEs,
    USERTYPE: UserTypeEs,
    POWERTYPE: PowerTypeEs,
    POWERS: PowersES,
    STATS: statisticsES,
    TRATAMIENTO: TratamientoES,
    ACTIONTYPE: ActionTypeES,
    ACTIONTYPECHILE: ActionTypeChileES,
    TITLES: {
      COMPANIES: 'Sociedades',
      FOLLOWERS: 'Seguidores',
      ORIGIN_COUNTRY: 'País de origen',
      SHARE_INFO: 'Compartir Información',
      LIBRARY: 'Biblioteca',
      EXPIRATIONS: 'Vencimientos',
      USERS: 'Usuarios',
      LOGS: 'Listado de Logs',
      PERSONS: 'Personas',
      PROFILE: 'Perfil',
    },
    CAPITAL: {
      SOCIAL: 'Capital Social',
      SUBS: 'Capital Social Suscrito',
      NOM_VALUE: 'Valor nominal',
      NOM_VALUE_TODAY: 'Valor nominal actual',
      SHARES: 'Acciones',
      SOCIAL_Q: 'Cuotas Sociales',
      TOTAL_CAPITAL_STOCK: 'Capital Social Total',
      FIXED_CAP: 'Capital Social Fijo',
      VARIABLE: 'Capital Social Variable',
      INT: 'Capital Social Integrado',
      CURRENCY_TYPE: 'Tipo de moneda',
      OVERVIEW: 'Resumen',
    },
    TABS: {
      GENERAL_INFO: 'Información general',
      EXPIRATIONS: 'Vencimientos',
      POWERS: 'Poderes',
      STATUTE: 'Estatuto',
      STATUTES: 'Estatutos',
      DOCS: 'Formation documents',
      MAP: 'Mapa Societario',
      BALANCES: 'Balances',
      CONTRATOS: 'Contratos',
      SHAREHOLDERS: 'Accionistas y capital social',
      PARTNERS: 'Socios y capital social',
      MEMBERS: 'Miembros',
      SUBJECTS: 'Sujetos',
      AUTHORITIES: 'Autoridades',
      DIRECTORY_REP: 'Directorio y Representantes legales',
      DIRECTORY: 'Directorio',
      MANAGMENT: 'Gerencia',
      ADMIN_REP: 'Administración y Representantes',
      ASSETS_PROVIDED: 'Bienes aportados',
    },
    UBICATION: {
      POSTAL_CODE: 'Codigo postal',
      JURISDICTION: 'Jurisdicción',
      COUNTRY: 'Pais',
      FLOOR: 'Piso',
      OFFICE_NUMBER: 'No. de oficina',
    },
    WIZARD: {
      DURATION_EXCERCISE: 'Duración y Ejercicio',
      DURATION_TERM: 'Plazo de duración',
      DURATION_TERM_COMPANY: 'Plazo de duración de la sociedad',
      YEAR_CLOSING: 'Cierre de ejercicio',
      ADDRESS: 'Domicilio',
      BYLAW: 'Estatuto',
      BYLAWS: 'Estatutos',
      BYLAW_MX: 'Contrato Constitutivo',
      CAPITAL_STOCK: 'Capital social',
      AMOUNT_SHARE: 'Acciones',
      TYPE_SHARE: 'Tipo de acciones',
      NOMINAL_VALUE: 'Valor nominal',
      OBJECT: 'Objeto',
      MAX_AMOUNT: 'Cantidad máxima',
      MIN_AMOUNT: 'Cantidad mínima',
      INDEF_TERM: 'Plazo indefinido',
      SUB_CAPITAL_STOCK: 'Capital social suscripto',
      INT_CAPITAL_STOCK: 'Capital social integrado',
      CAP_STOCK_AND_SHARES: 'Capital Social y Acciones',
      ADDRESS_AND_HEAD: 'Domicilio y Sede',
      ADDRESS_2: 'Dirección',
      DOOR: 'No. de puerta',
      APARTMENT: 'No. de Apartamento',
      HEADQUARTERS: 'Sede',
      HEAD_MUST_BE: 'La sede debe estar dentro del domicilio',
      OFFICE_TERM: 'Plazo de duración de los cargos',
      DIRECTORY: 'Directorio',
      MANAGERS: 'Gerencia',
      ADMIN: 'Administración',
      NO_INFO: 'No contiene información',
      AMOUNT_DIRECTORS: 'Cantidad Directores titulares',
      DIRECTORS_SUP: 'Directores suplentes',
      DIRECTORS_TIT: 'Directores titulares',
      MANAGERS_SUP: 'Gerentes suplentes',
      MANAGERS_TIT: 'Gerentes titulares',
      ADMINS_TIT: 'Administradores titulares',
      ADMINS_SUP: 'Administradores suplentes',
      AUTHORITIES_TIT: 'Autoridades titulares',
      AUTHORITIES_SUP: 'Autoridades suplentes',
      AMOUNT_DIRECTORS_SUP: 'Cantidad Directores suplentes',
      SOCIAL_QUOTAS: 'Cuotas sociales',
      INDET_MANAGERS_TIT: 'Cantidad indeterminada de gerentes titulares',
      INDET_MANAGERS_SUP: 'Cantidad indeterminada de gerentes suplentes',
      PUBLIC_REGISTRY: 'Número de Registro en Registro Público de Comercio',
    },
    LABELS: {
      DOC_DETAILS: 'Detalles del documento',
      DOC_SHARE: 'Compartir documento',
      SHARE_TO: 'Compartido a',
      ACTIVE: 'Activas',
      INACTIVE: 'Desactivadas',
      ALL: 'Todas',
      FOLDER_NAME: 'Nombre de la carpeta',
      RENAME: 'Cambiar Nombre',
      SELECT_FOLDER: 'Seleccionar Carpeta',
      DOC_DATE: 'Fecha del documento',
      DOC_CONTENT: 'Contenido de documento',
      AMOUNT_SHARE: 'Acciones',
      COMPANY_NAME: 'Nombre de la Sociedad',
      COMPANY_TYPE: 'Tipo de Sociedad',
      CORPORATE_DATA_CONTROL: 'Control de Datos Societarios',
      DOC_TYPE: 'Tipo de documento',
      DOC_NAME: 'Nombre del documento',
      PROCEDURE_TYPE: 'Tipo de trámite',
      PROCESS_NUM: 'Número de trámite',
      SELECT_DOCS: 'Seleccionar Documento(s)',
      SELECT_DOC: 'Seleccionar Documento',
      SELECT_PROCEDURES: 'Select procedure type',
      STATUS: 'Estado',
      VERIFICATION_STATUS: 'Estado de Verificación',
      VERIFIED: 'Verificadas',
      NOTVERIFIED: 'No verificadas',
      VERIFIED_BY: 'Verificado por',
      NOT_VERIFY: 'No verificado',
      VERIFY: 'Verificado',
      LAST_VERIFICATION: 'Última verificación',
      LAST_MODIFICATION: 'Última modificación',
      MODIFIED_BY: 'Modificado por',
      PREVIEW: 'Vista Previa',
      LOADING: 'Cargando',
      COMPANIES: 'Sociedades',
      COMPANY: 'Sociedad',
      CONVERT_TO: "Convertir a Sociedad",
      EDIT: 'Editar',
      DELETE: 'Eliminar',
      SELECT: 'Seleccionar',
      USER: 'Usuario',
      DOCUMENT_TYPE: 'Tipo de documento',
      NAME: 'Nombre',
      LAST_NAME: 'Apellido',
      PHONE: 'Teléfono',
      EMAIL: 'Email',
      ACTIONS: 'Acciones',
      FOLDER: 'Carpeta',
      CREATE: 'Crear',
      EXPIRATION_DATE: 'Fecha de vencimiento',
      APPROVAL_DATE: 'Fecha de aprobación',
      FILING_DATE: 'Fecha de presentación',
      EXPIRATION_TYPE: 'Tipo de vencimiento',
      EXPIRATION_STATUS: 'Estado del vencimiento',
      EXPIRATION: 'Vencimiento',
      AUTHORITY: 'Autoridad',
      DIRECTOR: 'Director',
      DIRECTORS: 'Directores',
      MANAGER: 'Gerente',
      MANAGERS: 'Gerentes',
      ADMIN: 'Administrador',
      ADMINS: 'Administradores',
      PRESIDENT: 'Presidente',
      VICEPRESIDENT: 'Vicepresidente',
      MANAGER_TIT: 'Gerente Titular',
      MANAGER_SUP: 'Gerente Suplente',
      ADMIN_TIT: 'Administrador Titular',
      ADMIN_SUP: 'Administrador Suplente',
      DIR_TIT: 'Director Titular',
      DIR_SUP: 'Director Suplente',
      SIND_TIT: 'Sindico Titular',
      SIND_SUP: 'Sindico Suplente',
      REP_LEG_TIT: 'Representante Legal Titular',
      REP_LEG_SUP: 'Representante Legal Suplente',
      SUBJECT: 'Sujeto',
      SUBJECTS: 'Sujetos',
      MEMBERS: 'Miembros',
      MEMBER: 'Miembro',
      TRUSTEE: 'Fideicomisario',
      FIDUCIARY: 'Fiduciario',
      OR_BENEF: 'Beneficiario originario',
      OR_TRUST: 'Fiduciante originario',
      ADHE_BENE: 'Beneficiario adherente',
      ADHE_TRUS: 'Fiduciante adherente',
      GENERAL_MANAGER: 'Gerente General',
      ALT_MANAGER: 'Gerente Suplente',
      SPEC_MANAGER: 'Gerente Especial',
      COMMISSARY: 'Comisario',
      ADMINISTRATOR: 'Administrador',
      INDEPENDENT_COUNSELOR: "Consejero Independiente",
      PATRIMONY_COUNSELOR: "Consejero Patrimonial",
      COUNSELOR: "Consejero",
      BOARD_SECRETARY: "Secretario del Consejo de Administración",
      NON_MEMBER_SECRETARY: "Secretario no miembro del consejo de Administración",
      VOWEL: 'Vocal',
      SECRETARY: 'Secretario',
      TREASURER: 'Tesorero',
      ADDNEW: '--AGREGAR NUEVO--',
      NEWAUTH: 'Nombre nueva autoridad',
      ADVISER: 'Consejero',
      TITULAR: 'Titular',
      SUPLENTE: 'Suplente',
      SUPLENTES: 'Suplentes',
      DENOMINATION: 'Denominación',
      PERSON: 'Persona',
      INDIVIDUALS: 'Personas Físicas',
      LEGAL_ENTITIES: 'Personas Jurídicas',
      BIRTH: 'Fecha de nacimiento',
      NATIONALITY: 'Nacionalidad',
      OCCUPATION: 'Profesión',
      MARITAL_STATUS: 'Estado civil',
      TITLE: 'Tratamiento',
      ID_TYPE: 'Tipo de documento',
      ID_NUMBER: 'Número de documento',
      PERSONAL_INFO: 'Datos de la persona',
      ADDITIONAL_INFO: 'Documento adicional',
      INSCRIPTION: 'Constancia de inscripción',
      STATUTE: 'Estatuto',
      LAST_INSCRIPTION: 'Última inscripción',
      ID_COPY: {
        AR: 'Copia de DNI/Pasaporte',
        CL: 'Copia de DNI/Pasaporte',
        PY: 'Copia de DNI/Pasaporte',
        UY: 'Copia de DNI/Pasaporte',
        US: 'Copia del ID card/Pasaporte',
        MX: 'Copia del ID card/Pasaporte',
        DF: 'Copia de DNI/Pasaporte',
      },
      NEW: 'nueva',
      OF: 'de',
      SHAREHOLDERS: 'Accionistas',
      SHAREHOLDER: 'Accionista',
      PARTNERS: 'Socios',
      PARTNER: 'Socio',
      LEGAL_REPRESENTATIVE: 'Representante legal',
      LEGAL_REPRESENTATIVES: 'Representantes legales',
      DOCUMENT: 'Documento',
      OPERATION_COUNTRIES: 'Paises de Operación',
      OPERATION_COUNTRY: 'País de Operación',
      ATTACH_MAP: 'Adjuntar mapa societario',
      ATTACH_MAP_CL: 'Adjuntar malla societaria',
      INFORMATION: 'Información',
      NB_PUBLIC_REGISTRY: 'Nro. de inscripción Registro Público',
      NB_TAX_ID: 'Número de identificación fiscal',
      NB_TAX_ID_SHORT: 'Nro. de identificación fiscal',
      ART_REGISTRATION: 'Art. de inscripción',
      INFO_REGISTRATION_YEAR: 'Régimen informativo anual',
      CHOOSE_LINK: 'Elige el documento que quieras vincular',
      UPLOAD_NEW_FILE: 'Cargar nuevo documento',
      UPLOAD_NEW_POA: 'Cargar nuevo poder',
      DETAIL_POA: "Detalle del Poder",
      SHARES: 'Acciones',
      QUOTAS: 'Cuotas',
      TOTALS: 'Totales',
      GENDER: 'El',
      QUESTION_ADD: 'La autoridad que desea agregar es',
      QUESTION_ADD_PERSON: '¿La persona que desea agregar es',
      LEGAL_ENTITY: 'Persona jurídica',
      SELECT_NEW: 'Seleccionar nueva autoridad',
      ADD_NEW: 'Añadir nueva',
      PERCENTAJE: 'Porcentaje',
      CAPITAL: 'Capital',
      FIXED_CAP: 'Capital social Fijo',
      VARIABLE_CAP: 'Capital social Variable',
      TOTAL_CAPITAL_STOCK: 'Capital Social Total',
      PROFESSIONAL: 'Profesional Responsable',
      CONTROL_BODIES: 'Organismos de control',
      ACTIVITY: 'Actividad',
      ACTIVITY_SII: 'Actividad y Código SII',
      SELECT_ACTIVITY: 'Seleccionar actividades',
      SELECT_CONTROL: 'Seleccionar organismo de control',
      DATE_INCORPORATION: 'Fecha de constitución',
      PAGES: 'Fojas',
      NUMBER: 'Número',
      YEAR: 'Año',
      SELECT_YEAR: 'Seleccione el año',
      REAL_STATE_CONSERVATIVE: 'Conservador Bienes Raices',
      PUBLICATION_EXTRACT:
        'Publicación extracto de constitución en el diario oficial',
      SUPERVISOR_BODY: 'Órgano supervisor',
      FANTASY_NAME: 'Nombre fantasía',
      COMMERCIAL_NUMBER: 'Nro de inscripción Registro de Comercio',
      INS_NUM_BANCO_CENTRAL_UY:
        'Número de inscripción al Banco Central de Uruguay',
      INS_NUM_BANCO_PREV_UY:
        'Número de inscripción al Banco de Previsión Social',
      DATE_BANCO_CENTRAL_UY:
        'Fecha de inscripción en el Banco Central de Uruguay',
      BOOK_CUSTODY: 'Custodia de libros',
      DENOMINATION_NAME: 'Denominación',
      PUBLIC_REGISTRY: 'Registro público',
      SAT_ACTIVITY: 'Actividad ante el SAT',
      IMSS: 'Registro patronal ante el IMSS',
      OWNERSHIP_PERCENTAJE: 'Participación',
      AMOUNT_SHARES: 'Acciones',
      ELEVATION_NUMBER: 'Número de elevación a escritura',
      POA_NAME: 'Nombre del Poder',
      GRANT_DATE: 'Fecha de otorgamiento',
      PROXIES_NAME: 'Nombre de los Apoderados',
      POWERS_CONFERRED: 'Facultades Otorgadas',
      PAID: 'Pagadas',
      FIXED_PORTION: 'Porción fija',
      VARIABLE_PORTION: 'Porción variable',
      AMOUNT_SOCIAL: 'Cantidad de cuotas sociales',
      NAME_LASTNAME: 'Nombre y apellido',
      POSITION: 'Cargo',
      TERM_EXPIRATION: 'Vencimiento de Mandato',
      DESIGNATION_DATE: 'Fecha de designación',
      COUNTRY: 'País',
      REPRESENTATIVE: 'Representante',
      APPOINTMENT_DATE: 'Fecha de nombramiento',
      POWER: 'Poder',
      POA_DETAILS: 'Detalle del poder',
      POA_TYPE: 'Tipo de poder',
      POA_INFORMATION: 'Información del poder',
      GENERAL_POA: 'Poder General',
      TYPE_GENERAL_POA: 'Tipo de poder general',
      SPECIAL_POA: 'Poder especial',
      TYPE_SPECIAL_POA: 'Tipo de poder especial',
      APPOINT_PROXIES: 'Designar Apoderados',
      SELECT_PROXIES: 'Seleccionar Apoderado',
      LIST_PROXIES: 'Lista de apoderados',
      SELECT_POWER: 'Seleccionar facultad',
      LIST_POWERS: 'Lista de facultades',
      NOTARY_REG_NUM: 'Número de registro notarial',
      FOLIO_NUMBER: 'Número de folio',
      FOLIO: 'Folio',
      NOTARY_REG: 'Reg. Not.',
      NOTARY_NAME: 'Nombre Notaría',
      NOTARY_PERSON: 'Nombre de notario',
      OBSERVATIONS: 'Observaciones',
      FOLIO_NUM: 'Nº Folio',
      NOTARY_REG_NUMB: 'Nº registro notarial',
      REPERTOIRE_NUMBER: 'Numero de repertorio',
      DEED_NUM: 'Número de escritura',
      DEED_NUMBER: 'Número de escritura',
      DEED_DATE_MX: 'Fecha de escritura',
      DEED_NMB: 'Nro. de escritura',
      DEED_DATE: 'Fecha del acta',
      NOTARY_NUMBER: 'Número notario',
      POWERS: 'Poderes',
      LAST_DATE_REGIME: 'Última fecha de cumplimiento del Régimen informativo',
      AMOUNT_OF: 'Cantidad de',
      BALANCE: 'Balance',
      APPROVAL: 'Aprobación',
      FILING: 'Presentacion',
      CONTRACT: 'Contrato',
      CONTRACT_TYPE: 'Tipo de contrato',
      OPEN_FIELD: 'Campo libre',
      ASSET_NAME: 'Nombre del bien',
      ASSET: 'Bien',
      VIEW_NAME: 'Nombre de vista',
      ROLE: 'Rol',
      EDIT_INFORMATION: 'Editar Información',
      PURPOSE: 'Objeto',
      REGISTERED_OFFICE: 'Domicilio Social',
      INF_ART_INCORPORATION: 'Información del acta constitutiva',
      USERNAME: 'Nombre del usuario',
      ENTRY_DATE: 'Fecha de alta',
      USER_TYPE: 'Tipo de usuario',
      INITIALS: 'Iniciales',
      SHARE_INFORMATION: 'Información Compartida',
      SIGN: 'Firmante',
      EDITOR: 'Editor',
      CLIENTE: 'Acceso Cliente',
      READ_ONLY: 'Solo lectura',
      CLICKORDER: 'Click para ordenar',
      AVAILABLE_CAPITAL: 'Disponible',
      NEW_CLASS: 'Nueva Clase',
      UNDEFINED: 'Indefinido',
      FOLIO_MER:'Folio Mercantil',
      FIRMA_LIM: 'Firma y Límites',
      SIGNATURE: 'Firma',
      LIMITS: 'Limites',
      CLASS: 'Clase'
    },
    OPTIONS: {
      LAW_COL: 'Juicios y cobros',
      ADMIN_ACTS: 'Actas de administración',
      ADMIN_ACTS_LAB: 'Actas de administración en asuntos laborales',
      PROP_ACTS: 'Actas de propiedad',
    },
    MESSAGES: {
      PREVIEW_NOT_FOUND: 'Vista previa no encontrada',
      SUCCESS_MAP: 'Mapa societario actualizado con éxito',
      ERROR_MAP: 'Ha ocurrido un error al actualizar mapa societario',
      DELETE_QUESTION: 'Está seguro que desea eliminar',
      UNLINK_QUESTION: 'Está seguro que desea desvincular',
      INACTIVE_QUESTION: '¿Estás seguro que quieres desactivar la sociedad? Vas a poder volver a activarla cuando lo elijas',
      ACTIVE_QUESTION: '¿Estás seguro que quieres activar la sociedad?',
      DELETE_COMP_QUESTION: '¿Estas seguro que quieres borrar la sociedad seleccionada?',
      REMEMBER_DOC:
        'Recuerde subir a la biblioteca el documento que respalda este cambio',
      SUCCESS_UPDATE: 'Se ha actualizado correctamente',
      ERROR_UPDATE: 'Ha ocurrido un error al actualizar',
      ERROR_INVALID: 'es un tipo de archivo no soportado',
      MUST_SELECT_PROXY: 'Debe seleccionar al menos un apoderado',
      MUST_SELECT_POWER: 'Debe seleccionar al menos una facultad',
      EDIT_CONTRACT: 'Editar contrato',
      ADD_CONTRACT: 'Agregar nuevo contrato',
      EDIT_ASSET: 'Editar bien',
      ADD_ASSET: 'Agregar nuevo bien',
      EDIT_BALANCE: 'Editar balance',
      ADD_BALANCE: 'Agregar nuevo balance',
      DELETE_CONTRACT: '¿Está seguro que desea eliminar el contrato?',
      USER_ADMIN:
        'Dirigido al dueño de la cuenta. Tiene acceso a todo incluyendo administración de usuarios y pagos.',
      USER_SIGN:
        'Puede editar la información e iniciar documentos y aprobarlos, pero no puede agregar nuevos usuarios.',
      USER_EDIT:
        'Puede editar la información e iniciar documentos, pero no puede aprobarlos ni agregar nuevos usuarios.',
      USER_CLIENT:
        'Podrá ver toda la información y los documentos de la sociedad que se le compartan.',
      USER_READ_ONLY: 'Solo puede leer la información.',
      OK_DOC: "Documento actualizado correctamente",
      ERR_DOC: "Ha ocurrido un error al actualizar documento",
      ERR_FOLDER: "No se pueden eliminar carpetas que contengan adentro documentos"
    },
    BUTTONS: {
      SHARE_FOLDER: 'Compartir Carpeta',
      SHARE_DOC: 'Compartir Documento',
      CLEAN_FILTER: 'Limpiar Filtros',
      OPEN: 'Abrir',
      FILTER: 'Filtrar',
      FILTERS: 'Filtros',
      UPDATE: 'Actualizar',
      GO_TO_LIBRARY: 'Ir a la biblioteca',
      CORPORATE_SUMMARY: 'Ver Reporte',
      VERIFY: 'Verificar',
      SHARE: 'Compartir',
      ATTACH_DOCS: 'Documentos Vinculados',
      SEE_ATTACH_DOCS: 'Ver Documentos Vinculados',
      SAVE: 'Guardar',
      MODIFY: 'Modificar',
      NEXT: 'Siguiente',
      PREVIOUS: 'Anterior',
      CHANGE_COUNTRY: 'Cambiar de País',
      CLOSE: 'Cerrar',
      NEW: 'Nuevo',
      NUEVA: 'Nueva',
      DOC: 'Documento',
      DOCS: 'Documentos',
      BY_DOC_NAME: 'Por nombre del documento',
      BY_FOLDER_NAME: 'Por nombre de carpeta',
      BY_CONTENT: 'Por contenido del documento',
      UPLOAD: 'Cargar',
      DELETE: 'Eliminar',
      DELETE_SELECTED: 'Eliminar seleccionados',
      MOVE_SELECTED: 'Mover seleccionados',
      DOWNLOAD: 'Descargar',
      MOVE_TO: 'Mover a',
      ADD: 'Agregar',
      ALL: 'Todos',
      CANCEL: 'Cancelar',
      RESOLVED: 'Resuelto',
      BACK: 'Volver',
      WHY_Q: '¿Por qué?',
      LINKAGES: 'Vinculaciones',
      FULL_VIEW: 'Vista Completa',
      LOCAL_VIEW: 'Vista Local',
      SAVE_VIEW: 'Guardar Vista',
      SEE: 'Ver Sociedad',
      LINK_FILE: 'Vincular Archivo',
      UNLINK: 'Desvincular',
      SEE_FILE: 'Ver Archivo',
      ADD_REP_LEG: 'Agregar Representante Legal',
      UPDATE_SHAREHOLDER: 'Editar accionista',
      EDIT: 'Editar',
      EDIT_POWER: 'Editar poder',
      ENTER: 'Ver poder',
      EDIT_PARTNER: 'Editar socio',
      LINK_FILES: 'Vincular documentos',
      GO_TO_UBICATION: 'Ir a la ubicacion del archivo',
      SAVE_CHANGES: 'Guardar cambios'
    },
    PLACEHOLDERS: {
      SEARCH: 'Buscar',
      USERS: 'Usuarios',
      SEARCH_USER: 'Buscar usuario',
      SEARCH_COMPANY: 'Buscar sociedad',
      SEARCH_BY_NAME: 'Buscar por Nombre',
      SEARCH_BY_LAST_NAME: 'Buscar por Apellido',
    },
    STATUTE: {
      DIRECTORY: 'Directorio',
      TERM_YEAR: 'Duración y ejercicio',
      TERM_COMPANY: 'Plazo de duración',
      YEAR_CLOSE: 'Cierre de ejercicio',
      COMPANY_EXPIRATION_DATE: 'Fecha de vencimiento de la sociedad',
      AUTHORITIES: 'Autoridades',
      INCUMBENT_DIR: 'Directores titulares',
      SUP_DIR: 'Directores suplentes',
      MAX_Q: 'Cantidad máxima',
      MIN_Q: 'Cantidad mínima',
      TERM_OFFICE: 'Plazo de duración de los cargos',
      OBJ: 'Objeto',
      OPEN_FIELD: 'Campo libre',
      NUM_DIC: 'Cantidad de directores',
      IND_TERM: 'Plazo Indeterminado',
      UNDET_NUM: 'Cantidad indeterminada de',
    },
    COMPANY_LIST: companyListES,
    SHARE_INFO_TABLE: ShareInfoTableES,
    DOC_FORMATION_TABLE: docFormationES,
    EXP_STUDIO_TABLE: expirationStudioES,
    IND: {
      NAME_LASTNAME: 'Nombre y Apellido',
      AUTHORITY: 'Autoridad',
      STOCK: 'Accionista',
      POWER: 'Apoderado',
      FULL_REPO: 'Reporte Completo',
      SHAREHOLDERS_REPO: 'Reporte Accionistas',
      FINAL_REPO: 'Reporte Beneficiarios Finales',
      ASK_TYPE_DOC: '¿Que informacion desea descargar?',
    },
    MENU: {
      LIBRARY: 'Biblioteca',
      DOCUMENTS: 'Documentos',
      PERSONS: 'Personas',
      COMPANIES: 'Sociedades',
      TRAMITES: 'Trámites a Registar',
      EXPIRATIONS: 'Alertas y Vencimientos',
      SHAREINFO: 'Compartir Información',
      ADD: 'Crear',
      CORPOTATE_MAP: 'Mapa Societario',
    },
    PROFILE: {
      LOGOUT: 'Salir',
      USERS_SETTINGS: 'Configuración de usuarios',
    },
    TABLES: {
      EMPTY: 'No hay registros',
    },
    TEXT: {
      YES: 'Si',
      NO: 'No',
      ADV_SEARCH: 'Busqueda Avanzada',
      CHOOSE_COUNTRY_SIMPLE: 'Seleccione el país de la sociedad',
      CHOOSE_COMPANY: 'Seleccione una sociedad',
      CHOOSE_COUNTRY: 'Elija el país correspondiente a la sociedad',
      ADD_CUSTOM_EXPIRATION: 'Agregar vencimiento personalizado',
      EDIT_CUSTOM_EXPIRATION: 'Editar vencimiento personalizado',
      INVALID_SELECTION: 'Seleccion inválida',
      SURE_TO_DELETE: '¿Seguro de desea eliminar el vencimiento?',
      SELECT_MODULE_ERROR: 'Seleccione al menos un módulo',
      POLITICALLY_EXPOSED: 'Persona políticamente expuesta',
      CHOOSE_DOC_TYPE: 'Seleccione el tipo de documento',
      CHOOSE_FOLDER_NAME: 'Seleccione una carpeta',
      MOVE_FILE: 'Mover Archivo',
      MOVE_FILES: 'Mover Archivos',
      NEW_FOLDER: 'Nueva Carpeta',
      MOVE: 'Mover',
      MOVE_HERE: 'Mover Aqui',
      MOVING_FILES: 'Moviendo archivos',
      OF: 'de',
      RENAME_FOLDER: 'Renombrar Carpeta',
      NEW_FOLDER_NAME: 'Nuevo nombre de Carpeta',
      CHOOSE_FAC: 'Seleccionar Facultad',
      CHOOSE_IND: 'Seleccionar Apoderado',
      POWERS: 'Facultades',
      IND: 'Apoderados',
    },
    VALIDATION_MESSAGES: {
      MAX_PERM: "El máximo permitido es ",
      MAX_2: "El máximo de caracteres es 2",
      MAX_20: "El máximo de caracteres es 20",
      MAX_30: "El máximo de caracteres es 30",
      MAX_40: "El máximo de caracteres es 40",
      MAX_100: "El máximo de caracteres es 100",
      MAX_150: "El máximo de caracteres es 150",
      MAX_500: "El máximo de caracteres es 500",
      MAX_10000: "El máximo de caracteres es 10000",
      
      MAX_DIG_2: "El máximo permitido es de 2 dígitos",
      MAX_DIG_30: "El máximo permitido es de 30 dígitos",
      MAX_DIG_100: "El máximo permitido es de 100 dígitos",
      
      MIN_DIG_0: "El número mínimo es 0",
      MIN_NUM_1: "El número mínimo es 1",
      MIN_0_0000000001: "El mínimo permitido es 0.0000000001",
      
      MAX_NUM_100: "El número máximo es 100",
      MAX_NUM_999: "El número máximo es 999",
      MAX_NUM_1000: "El número máximo es 1000",
      
      WHITE_SPACE: "No puede ingresar solo espacios en blanco",
      REQUIRED_FIELD: "Campo obligatorio",
      SEL_FIR: "Seleccione un tipo de firma",
      SEL_CAR: "Seleccione un cargo",
      
      SOLO_NUM_GUION: "Solo puede ingresar números y guiones",
      NUM_INV: "Número inválido",
      
      FEC_OTOR: "La fecha de otorgamiento no puede ser posterior a la expiración",
      FEC_EXP: "La fecha de expiración no puede ser anterior al otorgamiento"      
    },
    POA: {
      INDISTINCT: "Indistinta",
      INDIVIDUAL: "Individual",
      JOINT: "Conjunta"
    },
    // SETTING BY COUNTRY CODE
    BY_COUNTRY: {
      AR: {
        AUTHORITY: {
          REPRESENTATIVE: 'Asignar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'Número de Documento',
          LEGAL_DOCUMENT_TYPE: 'CUIT/CUIL',
          STREET: 'Dirección',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
          DEPARTMENT: 'Departamento',
        },
        UBICATION: {
          POSTAL_CODE: 'Código postal',
          ADDRESS: 'Dirección',
          TYPE: 'Sede Social',
          FOREING: 'Domicilio Local',
          PROVINCE: 'Provincia',
          LOCATION: 'Localidad',
          FIDEICOMISO_DOM: 'Domicilio',
          FIDEICOMISO: 'Ubicación del fideicomiso',
          FOREING_ADDRESS: 'Domicilio extranjero',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
          OFFICE_NUMBER: 'No. de oficina',
        },
      },
      DF: {
        AUTHORITY: {
          REPRESENTATIVE: 'Asignar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'Número de Documento',
          LEGAL_DOCUMENT_TYPE: 'CUIT',
          STREET: 'Dirección',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
          DEPARTMENT: 'Departamento',
        },
        UBICATION: {
          POSTAL_CODE: 'Código postal',
          ADDRESS: 'Dirección',
          TYPE: 'Sede Social',
          FOREING: 'Domicilio Local',
          FOREING_ADDRESS: 'Domicilio',
          PROVINCE: 'Provincia',
          LOCATION: 'Localidad',
          FIDEICOMISO_DOM: 'Domicilio',
          FIDEICOMISO: 'Ubicación del fideicomiso',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
          OFFICE_NUMBER: 'No. de oficina',
        },
      },
      CL: {
        AUTHORITY: {
          REPRESENTATIVE: 'Designar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'RUT',
          LEGAL_DOCUMENT_TYPE: 'RUT',
          STREET: 'Dirección',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
        },
        UBICATION: {
          POSTAL_CODE: 'Código postal',
          ADDRESS: 'Domicilio',
          TYPE: 'Sede Social',
          FOREING: 'Domicilio local',
          PROVINCE: 'Provincia',
          LOCATION: 'Comuna',
          FIDEICOMISO_DOM: 'Domicilio',
          FIDEICOMISO: 'Ubicación del fideicomiso',
          FOREING_ADDRESS: 'Domicilio extranjero',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
          OFFICE_NUMBER: 'No. de oficina',
        },
      },
      PY: {
        AUTHORITY: {
          REPRESENTATIVE: 'Designar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'RUC',
          LEGAL_DOCUMENT_TYPE: 'RUC',
          STREET: 'Calle',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
          DEPARTMENT: 'Departamento',
        },
        UBICATION: {
          POSTAL_CODE: 'Código postal',
          ADDRESS: 'Calle',
          TYPE: 'Domicilio',
          FOREING: 'Domicilio Local',
          PROVINCE: 'Departamento',
          LOCATION: 'Ciudad',
          FIDEICOMISO_DOM: 'Domicilio',
          FIDEICOMISO: 'Ubicación del fideicomiso',
          FOREING_ADDRESS: 'Domicilio extranjero',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
          OFFICE_NUMBER: 'No. de oficina',
        },
      },
      US: {
        INFO: {
          DOCUMENT_TYPE: 'TAX number',
          FILLING_NUMBER: 'Filling Number',
          REGISTERED_AGENT: 'Registered Agent',
        },
        AUTHORITY: {
          REPRESENTATIVE: 'Designar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'ID Card',
          LEGAL_DOCUMENT_TYPE: 'ID Card',
          STREET: 'Street',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
        },
        UBICATION: {
          POSTAL_CODE: 'Código postal',
          ADDRESS: 'Domicilio',
          TYPE: 'Sede Social',
          FOREING_ADDRESS: 'Domicilio extranjero',
          PROVINCE: 'Provincia',
          LOCATION: 'Comuna',
          FOREING: 'Domicilio local',
          FIDEICOMISO_DOM: 'Domicilio',
          FIDEICOMISO: 'Ubicación del fideicomiso',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
          OFFICE_NUMBER: 'No. de oficina',
        },
      },
      UY: {
        AUTHORITY: {
          REPRESENTATIVE: 'Designar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'RUT',
          LEGAL_DOCUMENT_TYPE: 'RUT',
          STREET: 'Dirección',
          FLOOR: 'No. de puerta',
          ADDRESS_NUMBER: 'No. de apartamento',
          DEPARTMENT: 'Departamento',
        },
        UBICATION: {
          POSTAL_CODE: 'Código postal',
          ADDRESS: 'Domicilio',
          TYPE: 'Domicilio',
          FOREING: 'Domicilio',
          PROVINCE: 'Departamento',
          LOCATION: 'Localidad',
          FIDEICOMISO_DOM: 'Domicilio',
          FIDEICOMISO: 'Ubicación del fideicomiso',
          FOREING_ADDRESS: 'Domicilio extranjero',
          STREET: 'Calle',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
          OFFICE_NUMBER: 'No. de oficina',
          DOOR: 'No. de puerta',
          APARTMENT: 'No. de apartamento',
        },
      },
      MX: {
        AUTHORITY: {
          REPRESENTATIVE: 'Asignar Apoderados',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'Número de Documento',
          LEGAL_DOCUMENT_TYPE: 'Número de identificación fiscal',
          STREET: 'Dirección',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
          DEPARTMENT: 'Departamento',
        },
        UBICATION: {
          POSTAL_CODE: 'Código postal',
          ADDRESS: 'Dirección',
          TYPE: 'Sede Social',
          FOREING: 'Domicilio Local',
          PROVINCE: 'Provincia',
          LOCATION: 'Localidad',
          FIDEICOMISO_DOM: 'Domicilio',
          FIDEICOMISO: 'Ubicación del fideicomiso',
          TAX_ADDRESS: 'Domicilio Fiscal',
          FOREING_ADDRESS: 'Domicilio Fiscal',
          STREET: 'Calle',
          FLOOR: 'Piso',
          ADDRESS_NUMBER: 'Número',
          OFFICE_NUMBER: 'No. de oficina',
        },
      },
    },
    LOGS: {
      DATE_FROM: "Fecha desde",
      DATE_TO: "Fecha hasta",
      USERS: "Usuarios",
      DATE: "Fecha",
      EMAIL: "Usuario",
      DETAIL: "Detalles",
      DETAIL2: "Detalles"
    },
    MARITAL_STATUS: {
      CASADO: "Casado/a",
      SOLTERO: "Soltero/a",
      DIVORCIADO: "Divorciado/a",
      VIUDO: "Viudo/a",
      UNION: "Unión Convivencial"
    }
  }
};
